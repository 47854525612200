import styled from "styled-components"
import IconButton from "@material-ui/core/IconButton"
import Tabs from "@material-ui/core/Tabs"

const DrawerWidth = `400px`

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  min-height: 100vh;
  height: 100vh;
  position: relative;
  overflow: hidden;

  .MuiDrawer-paper {
    border: 0;
  }
`

export const InnerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  transition: width 0.3s ease;
  iframe {
    border: 0;
    width: 100%;
    height: calc(100% + 45px);
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    ${({ isOpenDrawer }) =>
      isOpenDrawer ? `width: calc(100% - ${DrawerWidth})` : ""};
  } ;
`

export const BottomActions = styled.div`
  z-index: 1;
  //background-color:  ${({ theme }) => theme.palette.primary.main};
  transition: width 0.3s ease;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em 2em;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    .custom-button {
      padding: 0.8em;
      font-size: 11px;
    }
  }
`

export const ActionButton = styled(IconButton)`
  border-radius: 0;
  color: white;
  margin: 0 0.5em;
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  span {
    margin-left: 0.5em;
  }
`

export const DrawerWrapper = styled.div`
  width: ${DrawerWidth};
  background-color: white;
  //overflow: hidden;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100vw;
  }

  .react-swipeable-view-container {
    height: calc(100vh - 205px);
  }
`

export const DrawerHeader = styled.div`
  padding: 2em 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  .MuiIconButton-root {
    padding: 0;
  }
  p {
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    font-weight: bold;
  }
`

export const CustomTabs = styled(Tabs)`
  background-color: #2c3854;
  color: white;

  .MuiTab-wrapper {
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
  }

  .Mui-selected {
    .MuiTab-wrapper {
      font-weight: bold;
    }
  }
`

export const OptionsWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`

export const TitleWrapper = styled.div`
  display: flex;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(32, 32, 32, 0.08);
  width: 100%;
  background: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 2em;
  p {
    font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  }
`

export const CopyrightWrapper = styled.div`
  position: fixed;
  bottom: 0;
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 77px;
  border-top: 1px solid rgb(44, 56, 84, 0.4);
  background-color: white;
  width: 100%;

  p {
    color: #2c3854;
    font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  }

  a {
    font-weight: 600;
    text-decoration: none;
    color: #2c3854;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: ${DrawerWidth};
  }

  &.mobile {
    position: absolute;
    background-color: transparent;
    border-top: none;
    p,
    a {
      color: rgba(255, 255, 255, 0.43);
    }
  }
`
